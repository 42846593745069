import React from "react";
import { Link } from "gatsby";

import { Helmet } from "react-helmet";
import Layout from "../components/layout";

import "../scss/contactForm.scss";
import "../scss/auditMenu.scss";
import dmImage from "../images/dm-menu-image.jpg";
import dmIcon from "../images/dm-icon.svg";
import osImage from "../images/os-menu-image.jpg";
import osIcon from "../images/os-icon.svg";
import croImage from "../images/cro-menu-image.jpg";
import croIcon from "../images/cro-icon.svg";

class ourAudits extends React.Component{
    render() {
        const metadescription = "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950";
        const metatitle = "Independent Australian Performance Marketing Agency - Next&Co";
        const metaurl = typeof window !== 'undefined' ? window.location.href : '';

        return (
            <>
              <Helmet>
                <title>{metatitle}</title>
                <meta name="description" content={metadescription}/>
                <meta name="title" content={metatitle} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={metaurl} />
                <meta property="og:title" content={metatitle} />
                <meta property="og:description" content={metadescription} />
                <meta property="og:image" content="facebook.png" />
                <meta property="twitter:url" content={metaurl} />
                <meta property="twitter:title" content={metatitle} />
                <meta property="twitter:description" content={metadescription} />
                <meta property="twitter:image" content="twitter.png" />
              </Helmet>
              <Layout>
                <section
                  id="audit-menu-container"
                  className="is-active"
                >
                  <div className="outer-container">
                    <div className="container-max-width">
                      <div className="inner-container">
                        <Link className="navbar-item" to="/digital-media-audit">
                          <img src={dmImage} alt="" className="background" />
                          <div className="content">
                            <img src={dmIcon} alt="" className="icon" />
                            Digital Media Audit
                          </div>
                        </Link>
                        <Link className="navbar-item" to="/organic-search-audit">
                          <img src={osImage} alt="" className="background" />
                          <div className="content">
                            <img src={osIcon} alt="" className="icon" />
                            Search Engine Optimisation Audit
                          </div>
                        </Link>
                        <Link
                          className="navbar-item"
                          to="/conversion-rate-optimisation-audit"
                        >
                          <img src={croImage} alt="" className="background" />
                          <div className="content">
                            <img src={croIcon} alt="" className="icon" />
                            Conversion Rate Optimisation Audit
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </Layout>
            </>
        )
    }
}



export default ourAudits;
